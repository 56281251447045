import { message } from "ant-design-vue";

class NotifyClass {
  // 普通提示
  info(text) {
    message.info(text);
  }
  // 警告提示
  warning(text) {
    message.warning(text);
  }
  // 成功提示
  success(text) {
    message.success(text);
  }
  // 错误提示
  error(text) {
    message.error(text);
  }
}

const Notify = new NotifyClass();

export default Notify;
